import React from "react";
import { TittleSection } from "../TittleSection";
import avt_co_founder from "../../assets/images/About/avt_co-founder_small.png";
import avt_ceo from "../../assets/images/About/avt_ceo_small.png";
import avt_trang from "../../assets/images/About/avt_trang_small.png";
import { useTranslation } from "react-i18next";

interface CardInforProps {
  image: string;
  description: string;
  name: string;
  role: string;
}

export const PersonSection = () => {
  const { t } = useTranslation();

  const CardInfor = ({ image, description, name, role }: CardInforProps) => {
    return (
      <div className="bg-white min-h-[380px] sm:min-h-[300px] lg:min-h-[450px] w-1/2 sm:w-full rounded-[32px] px-6 py-10 flex flex-col items-center gap-4 relative">
        <img
          src={image}
          className="w-48 h-48 sm:w-32 sm:h-32 rounded-full"
          alt=""
        />
        <p className="text-green-900 mt-5">{name}</p>
        <p className="text-green-900">{role}</p>
      </div>
    );
  };

  return (
    <div>
      <TittleSection title={t("about_section_meet_founder")} />
      <div className="mx-auto flex gap-8 sm:w-full justify-between lg:w-full sm:gap-2 sm:flex-col">
        <CardInfor
          image={avt_co_founder}
          description={t("about_section_founder1_desc")}
          name={t("about_section_founder1_name")}
          role={t("about_section_founder1_role")}
        />
        <CardInfor
          image={avt_ceo}
          description={t("about_section_founder2_desc")}
          name={t("about_section_founder2_name")}
          role={t("about_section_founder2_role")}
        />
         <CardInfor
          image={avt_trang}
          description={t("about_section_founder3_desc")}
          name={t("about_section_founder3_name")}
          role={t("about_section_founder3_role")}
        />
      </div>
      <div className="w-2/3 mx-auto sm:w-full">
        <h2 className="text-[#306B1B] text-[24px] text-center mt-10 sm:text-[16px]">
          {t("about_section_meet_quote")}
        </h2>
      </div>
    </div>
  );
};
