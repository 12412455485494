import style from "../assets/styles/knowledge.module.css";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";


export const Source = ({ title, content, url }) => {
  const [iFrameHeight, setIFrameHeight] = useState('0px');
  const [isLoading, setIsLoading] = useState(true);
  const ref = useRef();
  const { t , i18n } = useTranslation();
  const location = useLocation();
  const { pathname } = location;

  
  useEffect(() => {
    const isEngPage = pathname.includes('/knowledge-') ||  pathname.includes('/news-');
    if (isEngPage) {
      i18n.changeLanguage('en');
    }
  }, [pathname]);

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className="w-full h-full relative">
          <div className={style.knowledge_banner_content}>
            <h1>{t(title)}</h1>
            <p>{t(content)}</p>
          </div>
        </div>
      </div>
      <main>
        {isLoading && <div className={style.loading_message}>{t('loading_message')}</div>}
        <iframe
          title="source"
          className={style.iframe}
          style={{width:'100%', height: iFrameHeight, overflow:'visible', visibility: isLoading ? 'hidden' : 'visible'}}
          onLoad={() => {
              if ((ref as any).current.contentWindow.document.body.scrollHeight) {
                setIsLoading(false);
                setIFrameHeight((ref as any).current.contentWindow.document.body.scrollHeight + 50 + 'px')
              }
          }} 
          src={url}
          width="100%" 
          height={iFrameHeight} 
          ref={ref}
          scrolling="no" 
          frameBorder="0"
        />
      </main>
    </div>
  );
};
