import { StatusSuccess } from "../../assets/images/svg/Icon";
import style from "./styles.module.css";

interface uspProps {
  content: string;
}

const USP = ({ content }: uspProps) => {
  return (
    <div className="flex items-center gap-2">
      <div className="flex-shrink-0">
        <StatusSuccess />
      </div>
      <h4 className={style.product_usp}>{content}</h4>
    </div>
  );
};

export default USP;
