import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import style from "../assets/styles/footer.module.css";
import logo_enfarm from "../assets/images/img/logo_enfarm.png";
import { FaceBookIcon, LinkedinIcon } from "../assets/images/svg/Icon";
import { useTranslation } from "react-i18next";

// interface ItemProps {
//   title: string;
//   href: string[];
// }
// const FooterItem = ({ title, href }: ItemProps) => {
//   return (
//     <div>
//       <h4>{title}</h4>
//       <div className="flex flex-col gap-2 mt-5">
//         {href.map((item, index) => (
//           <a className="cursor-pointer" key={index}>
//             {item}
//           </a>
//         ))}
//       </div>
//     </div>
//   );
// };

const Footer = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const isEngPage =
      pathname.includes("/knowledge-") || pathname.includes("/news-");
    if (isEngPage) {
      i18n.changeLanguage("en");
    }
  }, [pathname]);

  return (
    <footer className={style.container}>
      <div className={style.footer_info}>
        <div className="py-6 flex gap-16 sm:flex-col sm:gap-8">
          <div className="w-1/3 sm:w-full">
            <img src={logo_enfarm} className="w-[160px]" alt="logo_enfarm" />
            <p>{t("enfarm_slogan")}</p>
            <div className="flex gap-4 items-center mt-3">
              <button
                onClick={() =>
                  window.open("https://www.facebook.com/enfarmagritech")
                }
              >
                <FaceBookIcon />
              </button>
              <button
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/enfarm-agritech/"
                  )
                }
              >
                <LinkedinIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={style.footer_copyright}>
        <div className="flex sm:flex-col sm:gap-2">
          <div className="flex sm:flex-column sm:gap-2">
            <p className="w-4/5 sm:w-full">
              © enfarm Agritech Pte. Ltd.
              <br></br>
              <br></br>
              Công ty TNHH Công nghệ nông nghiệp enfarm
              <br></br>
              <br></br>
              MST: 0317604360
              <br />
              <br></br>
              <p>
                {t("address_head", {
                  address:
                    "410 North Bridge Road, #501 City Hall Building, Singapore, 188726",
                })}
              </p>
              <br></br>
              <p>
                {t("hcm_office", {
                  address:
                    "P301, Empire Tower, 28 Hàm Nghi, phường Bến Nghé, Quận 1, Hồ Chí Minh",
                })}
              </p>
              <br></br>
              <p>
                {t("daklak_office", {
                  address:
                    "212 Nguyễn Tất Thành, Thành phố Buôn Ma Thuột, Đắk Lắk",
                })}
              </p>
            </p>
          </div>
          <div className="w-2/5 sm:w-full flex lg:flex-col justify-between">
            <button onClick={() => window.open("tel:1800888900")}>
              <p>Hotline 1800 888 900</p>
            </button>
            <button
              onClick={() =>
                (window.location.href = "mailto:lienhe@enfarm.com")
              }
            >
              <p>lienhe@enfarm.com</p>
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
