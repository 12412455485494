import React, { ReactElement } from "react";
import { TittleSection } from "../TittleSection";
import style from "../../assets/styles/about.module.css";
import { useTranslation } from "react-i18next";
import {
  BalanceSale,
  Chart,
  FarmHome,
  FoodRamen,
  HeartBeat,
  PeopleTeam,
  WaterDrop,
  WeatherCloudy,
} from "../../assets/images/svg/Icon";
import achievement1 from "../../assets/images/About/achievement/achievement1-small.png";
import achievement2 from "../../assets/images/About/achievement/achievement2-small.png";
import achievement3 from "../../assets/images/About/achievement/achievement3-small.png";
import achievement4 from "../../assets/images/About/achievement/achievement4-small.png";
import achievement5 from "../../assets/images/About/achievement/achievement5-small.png";
import achievement6 from "../../assets/images/About/achievement/achievement6-small.png";
import achievement7 from "../../assets/images/About/achievement/achievement7-small.png";
import achievement8 from "../../assets/images/About/achievement/achievement8-small.png";

interface CardInforProps {
  iconFront: ReactElement;
  iconBack: ReactElement;
  image: string;
  title: string;
  content: string;
}

export const AboutSection = () => {
  const { t } = useTranslation();
  const CardInfor = ({
    iconFront,
    iconBack,
    image,
    title,
    content,
  }: CardInforProps) => {
    return (
      <div className={style.container}>
        <div className={style.card}>
          {/* <div className={style.front}>
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "16px",
                padding: "16px",
              }}
            >
              <div
                style={{
                  borderRadius: "16px",
                  background: "rgba(0, 0, 0, 0.4)",
                  padding: "16px",
                  height: "200px",
                }}
              >
                <div>{iconFront}</div>
                <h6>{title}</h6>
              </div>
            </div>
          </div> */}
          <div className={style.back}>
            <div>{iconBack}</div>
            <h6>{title}</h6>
            <br></br>
            <p>{content}</p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="">
      <TittleSection title={t("about_section_pr_title")} />
      {/* <h2 className="text-[#0C3900] text-center">
        {t("about_section_pr_title_mini")}
      </h2> */}
      <p className="w-2/3 sm:w-full mx-auto text-center mb-8 mt-2 text-base">
        {t("about_section_pr_content_mini")}
      </p>
      <div className="grid grid-cols-4 xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="grid-cols-1 h-[450px]">
          <CardInfor
            iconFront={<PeopleTeam className="fill-white" />}
            iconBack={<PeopleTeam className="fill-[#0C3900]" />}
            image={achievement1}
            title={t("about_section_pr_subtitle1")}
            content={t("about_section_pr_content1")}
          />
        </div>
        <div className="grid-cols-1 h-[450px]">
          <CardInfor
            iconFront={<FoodRamen className="fill-white" />}
            iconBack={<FoodRamen className="fill-[#0C3900]" />}
            image={achievement2}
            title={t("about_section_pr_subtitle2")}
            content={t("about_section_pr_content2")}
          />
        </div>
        <div className="grid-cols-1 h-[450px]">
          <CardInfor
            iconFront={<HeartBeat className="fill-white" />}
            iconBack={<HeartBeat className="fill-[#0C3900]" />}
            image={achievement3}
            title={t("about_section_pr_subtitle3")}
            content={t("about_section_pr_content3")}
          />
        </div>
        <div className="grid-cols-1 h-[450px]">
          <CardInfor
            iconFront={<WaterDrop className="fill-white" />}
            iconBack={<WaterDrop className="fill-[#0C3900]" />}
            image={achievement4}
            title={t("about_section_pr_subtitle4")}
            content={t("about_section_pr_content4")}
          />
        </div>
        <div className="grid-cols-1 h-[450px]">
          <CardInfor
            iconFront={<WeatherCloudy className="fill-white" />}
            iconBack={<WeatherCloudy className="fill-[#0C3900]" />}
            image={achievement5}
            title={t("about_section_pr_subtitle5")}
            content={t("about_section_pr_content5")}
          />
        </div>
        <div className="grid-cols-1 h-[450px]">
          <CardInfor
            iconFront={<Chart className="fill-white" />}
            iconBack={<Chart className="fill-[#0C3900]" />}
            image={achievement6}
            title={t("about_section_pr_subtitle6")}
            content={t("about_section_pr_content6")}
          />
        </div>
        <div className="grid-cols-1 h-[450px]">
          <CardInfor
            iconFront={<BalanceSale className="fill-white" />}
            iconBack={<BalanceSale className="fill-[#0C3900]" />}
            image={achievement7}
            title={t("about_section_pr_subtitle7")}
            content={t("about_section_pr_content7")}
          />
        </div>
        <div className="grid-cols-1 h-[450px]">
          <CardInfor
            iconFront={<FarmHome className="fill-white" />}
            iconBack={<FarmHome className="fill-[#0C3900]" />}
            image={achievement8}
            title={t("about_section_pr_subtitle8")}
            content={t("about_section_pr_content8")}
          />
        </div>
      </div>
    </div>
  );
};
