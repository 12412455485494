import React, { useState } from "react";
import style from "../../assets/styles/header.module.css";
import ScrollEvent from "../../hooks/ScrollEvent";
import logo_enfarm from "../../assets/images/img/logo_enfarm.png";
import logo_enfarm_green from "../../assets/images/img/logo_enfarm_green.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../../redux/layoutSlice";
import {
  DownloadIcon,
  MenuMobileBlack,
  MenuMobileWhite,
} from "../../assets/images/svg/Icon";
import { HeaderMobile } from "./HeaderMobile";
import { animateScroll as scroll } from "react-scroll";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

interface NavigationProps {
  navItem: string;
  link: string;
  page: number;
}
export const Header = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activePage = useSelector((state: any) => state.layoutSlice.activePage);
  const { pathname } = location;
  const isShowLanguageSection = [
    "/",
    "/about",
    "/product",
    "/knowledge",
  ].includes(pathname);
  const { scrollDirection, isInverted } = ScrollEvent();
  const handleToTop = () => {
    scroll.scrollToTop({
      smooth: true,
      duration: 500,
    });
  };
  const Navigation = ({ navItem, link, page }: NavigationProps) => {
    return (
      <div
        onClick={() => {
          navigate(link);
          dispatch(setActivePage(page));
          handleToTop();
        }}
        className={`${style.navItem} ${
          activePage === page ? style.active : ""
        }`}
      >
        {navItem}
      </div>
    );
  };
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClickDownload = () => {
    navigate("/");
    dispatch(setActivePage(0));

    setTimeout(() => {
      const downloadElement = document.getElementById("download");
      if (downloadElement) {
        downloadElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
  };

  const onChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };

  return (
    <>
      <HeaderMobile isOpen={isOpen} setIsOpen={setIsOpen} />
      <div
        className={`${style.header} ${
          scrollDirection === "down" ? style.down : style.up
        } ${isInverted ? style.inverted : ""}`}
      >
        <div className="w-full flex justify-between items-center">
          <img
            onClick={() => {
              navigate("/");
              handleToTop();
            }}
            src={isInverted ? logo_enfarm_green : logo_enfarm}
            className="h-16 cursor-pointer"
            alt="logo enfarm"
          />
          <div className="flex items-center gap-4 mx-8 lg:hidden sm:hidden">
            <Navigation navItem={t("home")} link="/" page={0} />
            <Navigation navItem={t("about")} link="/about" page={1} />
            <Navigation navItem={t("product")} link="/product" page={2} />
            <Navigation navItem={t("know_how")} link="/knowledge" page={3} />
            <Navigation navItem={t("map")} link="/map" page={4} />
          </div>
          <div
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className="hidden lg:block sm:block sm:z-10"
          >
            {isInverted ? <MenuMobileBlack /> : <MenuMobileWhite />}
          </div>
          <div className={`flex items-center gap-3 ${style.language_section}`}>
            {isShowLanguageSection && (
              <div className={`${isInverted ? "text-black" : "text-white"}`}>
                <button
                  className={i18n.language === "vn" ? "font-bold" : null}
                  onClick={() => onChangeLanguage("vn")}
                >
                  VI
                </button>{" "}
                |{" "}
                <button
                  className={i18n.language === "en" ? "font-bold" : null}
                  onClick={() => onChangeLanguage("en")}
                >
                  EN
                </button>
              </div>
            )}
            <div>
              {/* <Link
                to="download"
                spy={true}
                smooth={true}
                offset={50}
                duration={1500}
              >
                <button
                  onClick={handleButtonClickDownload}
                  className={style.btn}
                >
                  {t("download")}
                  <DownloadIcon />
                </button>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
