import double_screen_mock from "../assets/images/Product/double_screen_mock.png";
import gg_play from "../assets/images/img/gg_play.png";
import app_store from "../assets/images/img/app_store.png";
import gg_play_en from "../assets/images/img/gg_play_en.png";
import app_store_en from "../assets/images/img/app_store_en.png";
import style from "../assets/styles/product.module.css";
import { useTranslation } from "react-i18next";
import DeviceSection from "../layouts/DeviceSection";
import CertSection from "../layouts/CertSection";

export const Product = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const goToGGPlay = () => {
    window.open(t("link_gg_play"), "_blank");
  };
  const goToAppStore = () => {
    window.open(t("link_ios"), "_blank");
  };
  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className="w-full h-full relative">
          <div className={style.prouduct_banner_content}>
            <h1>{t("product_banner_title")}</h1>
            <p>{t("product_banner_content")}</p>
          </div>
        </div>
      </div>
      <div className={style.section}>
        <DeviceSection />
      </div>
      <div className={style.section}>
        <div className="w-full flex sm:flex-col items-center rounded-[48px] bg-[#EEF3E5] gap-4 relative">
          <div className="absolute top-1/2 -translate-y-1/2 left-8 sm:hidden lg:hidden">
            <img src={double_screen_mock} alt="" />
          </div>
          <div className="w-1/2 flex justify-center sm:w-full">
            <img src={double_screen_mock} alt="" className="hidden lg:block" />
          </div>
          <div className="w-1/2 flex flex-col items-center justify-center gap-5 sm:w-full p-8 h-[480px] sm:h-full">
            <h1>{t("product_section_about_title")}</h1>
            <p>{t("product_section_about_content")}</p>
            <div className="w-full flex gap-4">
              <img
                onClick={() => goToGGPlay()}
                loading="lazy"
                src={language === "en" ? gg_play_en : gg_play}
                alt=""
                className="w-1/2"
              />
              <img
                onClick={() => goToAppStore()}
                loading="lazy"
                src={language === "en" ? app_store_en : app_store}
                alt=""
                className="w-1/2"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={style.section}>
        <CertSection />
      </div>
    </div>
  );
};
