import React from 'react';
import { useTranslation } from "react-i18next";
import styles from '../../assets/styles/chatbot.module.css';
import enfarmLogo from '../../assets/images/Mascot/mascot.gif';

const ChatbotFloatingButton: React.FC = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open("https://forms.gle/Aj6zXjjynMsmiCbdA", "_blank");
  };

  return (
    <div className={styles.chatbotContainer} onClick={handleClick}>
      <img src={enfarmLogo} alt="Chatbot Mascot" className={styles.logo} />
      <span className={styles.label}>Nhận ưu đãi</span>
    </div>
  );
};

export default ChatbotFloatingButton;
