import React from "react";
import style from "../../assets/styles/section.module.css";
import { TittleSection } from "../TittleSection";
import { FeatureItem } from "../FeatureItem";
import {
  ChartIcon,
  PhoneBuzzIcon,
  PlantIcon,
  WormIcon,
} from "../../assets/images/svg/Icon";
import enfarm_mockup from "../../assets/images/Home/enfarm_mockup.png";
import { Fade } from "react-reveal";
import { ProgressiveImg } from "../../components/ProgressiveImg";
import { useTranslation } from "react-i18next";

export const FeatureSection = () => {
  const { t } = useTranslation();

  return (
    <div className={style.section}>
      <TittleSection title={t("home_section_feature_title")} />
      <p className="w-2/3 sm:w-full mx-auto text-center mb-8 mt-2 text-base">
        {t("home_banner_pr_content")}
      </p>
      <h2 className="text-[#0C3900] text-center">
        {t("home_banner_pr_title")}
      </h2>

      <div className={style.content_parent}>
        <div className={style.content_child}>
          <Fade bottom>
            <FeatureItem
              iconSvg={<PhoneBuzzIcon />}
              title={t("home_section_feature_feat1_title")}
              content={t("home_section_feature_feat1_content")}
            />
          </Fade>
          <Fade bottom>
            <FeatureItem
              iconSvg={<WormIcon />}
              title={t("home_section_feature_feat2_title")}
              content={t("home_section_feature_feat2_content")}
            />
          </Fade>
        </div>
        <div className={style.content_child}>
          <ProgressiveImg
            hash={"WDLOWWNG4Ut68xs;00a_%3ax%3jb}TjYI:WXIqj[00ju-qf6t8ax"}
            src={enfarm_mockup}
            className="w-full"
          />
        </div>
        <div className={style.content_child}>
          <Fade bottom>
            <FeatureItem
              iconSvg={<ChartIcon />}
              title={t("home_section_feature_feat3_title")}
              content={t("home_section_feature_feat3_content")}
            />
          </Fade>
          <Fade bottom>
            <FeatureItem
              iconSvg={<PlantIcon />}
              title={t("home_section_feature_feat4_title")}
              content={t("home_section_feature_feat4_content")}
            />
          </Fade>
        </div>
      </div>
    </div>
  );
};
