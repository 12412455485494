import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useState } from "react";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import { TittleSection } from "../TittleSection";
import styleSection from "./styles.module.css";
import style from "../../assets/styles/section.module.css";
import cnHopQuy from "../../assets/images/Cert/cn-hop-quy.png";
import cnWasi from "../../assets/images/Cert/cn-wasi.png";
import top5 from "../../assets/images/Cert/top5-ai.png";
import top10 from "../../assets/images/Cert/top10-impact.png";
import top15 from "../../assets/images/Cert/top15-VIC.png";
import top20 from "../../assets/images/Cert/top20-hang-viet.png";

import { useTranslation } from "react-i18next";

const settings = {
  dots: true,
  infinite: true,
  speed: 2400,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2400,
  arrows: false,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1279,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
        arrows: false,
        speed: 2400,
      },
    },
    {
      breakpoint: 767,
      settings: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        initialSlide: 0,
        arrows: false,
        speed: 2400,
      },
    },
    {
      breakpoint: 479,
      settings: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        initialSlide: 0,
        arrows: false,
        speed: 2400,
      },
    },
  ],
};

const CertSection = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [lightboxImages, setLightboxImages] = useState([]);

  const certImages = [
    {
      src: cnWasi,
      alt: t("cert.cnWasi.alt"),
      title: t("cert.cnWasi.title"),
      subTitle: t("cert.cnWasi.subTitle"),
    },
    {
      src: cnHopQuy,
      alt: t("cert.cnHopQuy.alt"),
      title: t("cert.cnHopQuy.title"),
      subTitle: t("cert.cnHopQuy.subTitle"),
    },
  ];

  const imagesPrizes = [
    {
      src: top5,
      alt: t("prizes.top5.alt"),
      top: t("prizes.top5.top"),
      title: t("prizes.top5.title"),
    },
    {
      src: top10,
      alt: t("prizes.top10.alt"),
      top: t("prizes.top10.top"),
      title: t("prizes.top10.title"),
    },

    {
      src: top15,
      alt: t("prizes.top15.alt"),
      top: t("prizes.top15.top"),
      title: t("prizes.top15.title"),
    },

    {
      src: top20,
      alt: t("prizes.top20.alt"),
      top: t("prizes.top20.top"),
      title: t("prizes.top20.title"),
    },
  ];

  const openLightbox = (images, index) => {
    setLightboxImages(images);
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <div className={style.section}>
      <div className={styleSection.bootstrapWrapper}>
        <TittleSection title={t("cert_section_title_cert")} />
        <div className={styleSection.cert}>
          {certImages.map((item, index) => (
            <div
              key={index}
              className={styleSection.certBlocks}
              onClick={() => openLightbox(certImages, index)}
            >
              <div className={styleSection.certImage}>
                <img src={item.src} alt={item.alt} />
              </div>
              <div className={styleSection.certInfo}>
                {/* <div className={styleSection.labelCN}>{t("cert.labelCN")}</div> */}
                <div className={styleSection.certTitle}>{item.title}</div>
                <div className={styleSection.certSubTitle}>{item.subTitle}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={lightboxImages[photoIndex].src}
          nextSrc={lightboxImages[(photoIndex + 1) % lightboxImages.length].src}
          prevSrc={
            lightboxImages[
              (photoIndex + lightboxImages.length - 1) % lightboxImages.length
            ].src
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + lightboxImages.length - 1) % lightboxImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % lightboxImages.length)
          }
        />
      )}
    </div>
  );
};

export default CertSection;
