import { TittleSection } from "../TittleSection";
import style from "../../assets/styles/section.module.css";
import { useTranslation } from "react-i18next";
import DeviceSection from "../DeviceSection/index";

export const ProductionSection = () => {
  const { t } = useTranslation();

  return (
    <div className={style.section}>
      <TittleSection title={t("home_section_prod_title")} />
      <DeviceSection />
    </div>
  );
};
