import { useState, useEffect } from "react";
import ImgPost1 from "../assets/images/Knowledge/anh2.jpeg";
import ImgPost2 from "../assets/images/Knowledge/anh7.jpeg";
import ImgPost3 from "../assets/images/Knowledge/anh9.jpg";

import style from "../assets/styles/knowledge.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setTabsKnowledge } from "../redux/layoutSlice";
import { CaretRight } from "../assets/images/svg/Icon";
import { useTranslation } from "react-i18next";

interface MiniPostProps {
  image?: string;
  date?: string;
  title?: string;
  content?: string;
  link?: string;
}
export const Knowledge = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const lst_news = t("lst_news", { returnObjects: true }) || [];

  const [stateNews, setStateNews] = useState([]);

  let dispatch = useDispatch();
  const tabsKnowledge = useSelector(
    (state: any) => state.layoutSlice.tabsKnowledge
  );
  const shorten = (title: string, maxLength: number) => {
    if (title.length > maxLength) {
      return title.slice(0, maxLength) + "...";
    }
    return title;
  };

  const handleReadMore = () => {
    setStateNews(lst_news);
  };

  useEffect(() => {
    setStateNews((lst_news as any).slice(0, 4));
  }, [language]);

  const renderList = () => {
    return (stateNews as any).map(function (item: any) {
      return (
        <div
          key={item.link}
          className="col-span-1 rounded-[32px] overflow-hidden bg-[#F1F1EA]"
        >
          <MiniPostKnow
            image={item.avatar}
            date={item.know_section_news_post_date}
            title={item.know_section_news_post_title}
            content={item.know_section_news_post_subtitle}
            link={item.links}
          />
        </div>
      );
    });
  };

  const renderListNews = () => {
    return (stateNews as any).map(function (item: any) {
      return (
        <div
          key={item.link}
          className="col-span-1 rounded-[8px] overflow-hidden bg-[#F1F1EA] mt-3"
        >
          <MiniPostNews
            image={item.avatar}
            title={item.know_section_news_post_title}
            link={item.links}
          />
        </div>
      );
    });
  };
  const MiniPostKnow = ({
    image,
    date,
    title,
    content,
    link,
  }: MiniPostProps) => {
    return (
      <div className="min-h-[420px]">
        <div className={style.post}>
          <div className="w-full overflow-hidden a-16-9">
            <img
              src={image}
              className="object-cover object-top h-full w-full"
              alt=""
            />
          </div>
          <div className="p-4">
            <p className={style.date}>{date}</p>
            <h3 className={style.title}>{shorten(title, 55)}</h3>
            <p className={style.content}>{shorten(content, 130)}</p>
            <button className={style.read_more}>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {t("read_more")}
                <CaretRight />
              </a>
            </button>
          </div>
        </div>
      </div>
    );
  };
  const MiniPostNews = ({ image, title, link }: MiniPostProps) => {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        <div className="flex gap-3">
          <div className="w-2/5">
            <img
              src={image}
              className="object-cover object-center rounded-[8px]"
              alt=""
            />
          </div>
          <div className="w-3/5 p-4">
            <h3 className={style.title_mini_news}>{title}</h3>
          </div>
        </div>
      </a>
    );
  };
  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className="w-full h-full relative">
          <div className={style.knowledge_banner_content}>
            <h1>{t("know_banner_title")}</h1>
            <p>{t("know_banner_content")}</p>
          </div>
        </div>
      </div>
      <main>
        <div className={style.section}>
          <div className={style.navigation}>
            <button
              onClick={() => dispatch(setTabsKnowledge(0))}
              className={tabsKnowledge === 0 ? style.active : "px-4 py-2"}
            >
              {t("all")}
            </button>
            <button
              onClick={() => dispatch(setTabsKnowledge(1))}
              className={tabsKnowledge === 1 ? style.active : "px-4 py-2"}
            >
              {t("know_how")}
            </button>
            <button
              onClick={() => dispatch(setTabsKnowledge(2))}
              className={tabsKnowledge === 2 ? style.active : "px-4 py-2"}
            >
              {t("news")}
            </button>
          </div>
          {tabsKnowledge === 0 && (
            <div className="flex w-full gap-[32px] sm:w-full sm:flex-col lg:w-full lg:flex-col mt-10 mb-20 sm:mt-3 sm:gap-20">
              <div className="w-2/3 sm:w-full lg:w-full">
                <div className="grid grid-cols-2 sm:grid-cols-1 gap-8 py-6 relative">
                  <div className="absolute top-0 -translate-y-1/2 z-[2] bg-white px-2">
                    {/* <h1>{t("know_how")}</h1> */}
                  </div>

                  {renderList()}
                </div>
                {lst_news.length !== stateNews.length && (
                  <button
                    onClick={() => handleReadMore()}
                    className={style.btn}
                  >
                    {t("read_more")}
                  </button>
                )}
              </div>
              <div className="w-1/3 sm:w-full lg:w-full">
                <div className={style.wrapper_mini}>
                  <div className="absolute top-0 -translate-y-1/2 z-[2] bg-white px-2">
                    <h1>{t("news")}</h1>
                  </div>
                  {renderListNews()}
                  {lst_news?.length !== stateNews?.length && (
                    <button
                      onClick={() => handleReadMore()}
                      className={style.btn}
                    >
                      {t("read_more")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
          {tabsKnowledge === 1 && (
            <div className="flex w-full gap-[32px] sm:w-full sm:flex-col lg:w-full lg:flex-col mt-10 mb-20 sm:mt-3">
              <div className="w-full">
                {/* <h1>Tri thức</h1> */}
                <div className="grid grid-cols-3 sm:grid-cols-1 gap-8 mt-4">
                  <div className="col-span-1 rounded-[32px] overflow-hidden bg-[#F1F1EA]">
                    <MiniPostKnow
                      image={
                        "https://enfarm-buckets.enfarm.com/enfarm-post/ph-measurement.jpg"
                      }
                      date={t("know_section_knowledge_post0_date")}
                      title={t("know_section_knowledge_post0_title")}
                      content={t("know_section_knowledge_post0_subtitle")}
                      link={t("links.knowledge-0")}
                    />
                  </div>
                  <div className="col-span-1 rounded-[32px] overflow-hidden bg-[#F1F1EA]">
                    <MiniPostKnow
                      image={ImgPost1}
                      date={t("know_section_knowledge_post1_date")}
                      title={t("know_section_knowledge_post1_title")}
                      content={t("know_section_knowledge_post1_subtitle")}
                      link={t("links.knowledge-1")}
                    />
                  </div>
                  <div className="col-span-1 rounded-[32px] overflow-hidden bg-[#F1F1EA]">
                    <MiniPostKnow
                      image={ImgPost2}
                      date={t("know_section_knowledge_post2_date")}
                      title={t("know_section_knowledge_post2_title")}
                      content={t("know_section_knowledge_post2_subtitle")}
                      link={t("links.knowledge-2")}
                    />
                  </div>
                  <div className="col-span-1 rounded-[32px] overflow-hidden bg-[#F1F1EA]">
                    <MiniPostKnow
                      image={ImgPost3}
                      date={t("know_section_knowledge_post3_date")}
                      title={t("know_section_knowledge_post3_title")}
                      content={t("know_section_knowledge_post3_subtitle")}
                      link={t("links.knowledge-3")}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {tabsKnowledge === 2 && (
            <div className="flex w-full gap-[32px] sm:w-full sm:flex-col lg:w-full lg:flex-col mt-10 mb-20 sm:mt-3">
              <div className="w-full">
                {/* <h1>{t("news")}</h1> */}
                <div className="grid grid-cols-3 sm:grid-cols-1 gap-8 mt-4">
                  {renderList()}
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};
