import React from "react";
import enfarm_logo from "../../assets/images/img/enFarm_logo_web.png";
import enfarm_mockup from "../../assets/images/Home/bgNphone_mockup.png";
import app_store from "../../assets/images/img/app_store.png";
import gg_play from "../../assets/images/img/gg_play.png";
import gg_play_en from "../../assets/images/img/gg_play_en.png";
import app_store_en from "../../assets/images/img/app_store_en.png";
import style from "../../assets/styles/section.module.css";
import { useTranslation } from "react-i18next";



export const DownloadSection = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  
  const goToGGPlay = () => {
    window.open(t("link_gg_play"), '_blank');
  };

  const goToAppstore = () => {
    window.open(t("link_ios"), '_blank');
  };
  
  return (
    <div className={style.section} id="download">
      <div className="relative w-full">
        <img src={enfarm_mockup} alt="" className="sm:hidden w-full" />
        <div className="absolute top-1/2 left-24 -translate-y-1/2 w-1/2 sm:left-1/2 sm:-translate-x-1/2 sm:top-0 sm:hidden">
          <div className="w-16 h-16 rounded-2xl flex justify-center items-center">
            <img loading="lazy" src={enfarm_logo} alt="enfarm logo" />
          </div>
          <div>
            <h1 className="my-4 text-left">
              {t("home_section_download_title")}
            </h1>
            <article>{t("home_section_download_desc")}</article>
            <div className="w-full flex mt-9">
              <div className="w-1/3">
                <img onClick={() => goToGGPlay()} loading="lazy" src={language === 'en' ? gg_play_en : gg_play} alt="" className="w-full" />
              </div>
              <div className="w-1/3">
                <img onClick={() => goToAppstore()} loading="lazy" src={language === 'en' ? app_store_en : app_store} alt="" className="w-full" />
              </div>
            </div>
          </div>
        </div>
        <div className="hidden sm:block bg-[#E6F6D9] p-4 rounded-[32px]">
          <div className="w-16 h-16 rounded-2xl flex justify-center items-center">
            <img loading="lazy" src={enfarm_logo} alt="enfarm logo" />
          </div>
          <div>
            <h1 className="my-4 text-left">
              {t("home_section_download_title")}
            </h1>
            <article>{t("home_section_download_desc")}</article>
            <div className="w-full flex sm:flex-col sm:items-center mt-9">
              <img onClick={() => goToGGPlay()} loading="lazy" src={language === 'en' ? gg_play_en : gg_play} alt="" className="w-1/2" />
              <img onClick={() => goToAppstore()}  loading="lazy" src={app_store} alt="" className="w-1/2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
