import React from "react";
import { TittleSection } from "../TittleSection";
import about_1 from "../../assets/images/About/about-sumenh-image-1.png";
import about_2 from "../../assets/images/About/about-sumenh-image-2.png";
import about_3 from "../../assets/images/About/about-sumenh-image-3.png";
import { useTranslation } from "react-i18next";

export const MissionSection = () => {
  const { t } = useTranslation();

  return (
    <div>
      <TittleSection title={t("about_section_mision_title")} />
      <div className="flex  sm:flex-col gap-8">
        <div className="w-1/3 flex flex-col items-center gap-4 sm:w-full sm:p-0">
          <img src={about_1} alt="" />
          <h2 className="text-center">{t("about_section_mis1_title")}</h2>
          <p className="text-center">{t("about_section_mis1_content")}</p>
        </div>
        <div className="w-1/3 flex flex-col items-center gap-4 sm:w-full sm:p-0">
          <img src={about_2} alt="" />
          <h2 className="text-center">{t("about_section_mis2_title")}</h2>
          <p className="text-center">{t("about_section_mis2_content")}</p>
        </div>
        <div className="w-1/3 flex flex-col items-center gap-4 sm:w-full sm:p-0">
          <img src={about_3} alt="" />
          <h2 className="text-center">{t("about_section_mis3_title")}</h2>
          <p className="text-center">{t("about_section_mis3_content")}</p>
        </div>
      </div>
    </div>
  );
};
