import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import logo from "../assets/images/logo/logo-frame.svg";
import styles from "../assets/styles/qrcode.module.css";

const QRCodeGenerator = () => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleDownload = () => {
    const svg = document.getElementById('qrcode');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    // Set up the desired QR code size and scaling factor
    const qrSize = 512; // size of the QR code in pixels
    const logoSize = 128; // size of the logo in pixels
    const padding = 8; // fixed padding around the QR code in pixels
    const scaleFactor = 4; // Increase this value for higher resolution
    const totalSize = (qrSize + padding * 2) * scaleFactor; // total canvas size including padding, scaled
  
    canvas.width = totalSize;
    canvas.height = totalSize;
  
    // Scale the context to improve the resolution
    ctx.scale(scaleFactor, scaleFactor);
  
    // Draw white background
    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, totalSize / scaleFactor, totalSize / scaleFactor);
  
    const img = new Image();
    img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
    img.onload = () => {
      // Draw QR code
      ctx.drawImage(img, padding, padding, qrSize, qrSize);
  
      // Draw Logo
      const logoImg = new Image();
      logoImg.src = logo;
      logoImg.onload = () => {
        ctx.drawImage(
          logoImg,
          padding + (qrSize - logoSize) / 2,
          padding + (qrSize - logoSize) / 2,
          logoSize,
          logoSize
        );
  
        // Get current timestamp
        const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
  
        // Convert canvas to image and download
        const pngFile = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngFile;
        downloadLink.download = `qr-code-${inputValue}-${timestamp}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };
    };
  };
  
  return (
    <div className={styles.qrPageContainer}>
      <div className={styles.qrContainer}>
        <h1 className={styles.title}>QR Code Generator</h1>
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          placeholder="Enter text to generate QR code"
          className={styles.qrInput}
        />
        <div className={styles.qrCode}>
          {inputValue && (
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <QRCode
                id="qrcode"
                value={inputValue}
                size={256}
                level={"H"}
                style={{ borderRadius: '8px' }}
              />
              <img
                src={logo}
                alt="Logo"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '64px',
                  height: '64px',
                  borderRadius: '8px',
                }}
              />
            </div>
          )}
        </div>
        {inputValue && (
          <button className={styles.downloadButton} onClick={handleDownload}>
            Download QR Code
          </button>
        )}
      </div>
    </div>
  );
};

export default QRCodeGenerator;
