import React from "react";
import style from "../../assets/styles/section.module.css";
import styleCarousel from "../../assets/styles/carousel.module.css";
import { TittleSection } from "../TittleSection";
import { Carousel } from "../../components/Carousel";
import img_story_1 from "../../assets/images/Home/img_story_1.png";
import img_story_2 from "../../assets/images/Home/img_story_2.png";
import img_story_3 from "../../assets/images/Home/img_story_3.jpg";

import { useTranslation } from "react-i18next";

interface MiniPostProps {
  image: string;
  title: string;
  content: string;
  desc?: string;
}

export const StorySection = () => {
  const { t } = useTranslation();
  const MiniPost = ({ image, title, desc, content }: MiniPostProps) => {
    return (
      <div className="p-4">
        <div className={styleCarousel.story_carousel}>
          <div className="relative w-full h-52">
            <div className="w-52 h-52 lg:w-40 lg:h-40 overflow-hidden rounded-full absolute -bottom-8 left-1/2 -translate-x-1/2">
              <img src={image} className="object-cover object-center h-full w-full" alt="" />
            </div>
          </div>
          <div className={`bg-[#EEF3E5] px-6 py-8 rounded-[32px] ${style.story_section}`}>
            <h3 className={styleCarousel.title}>{title}</h3>
            <p className={styleCarousel.desc}>{desc}</p>
            <br></br>
            <p className={styleCarousel.content} dangerouslySetInnerHTML={
              {__html: content}
            } />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={style.section}>
      <TittleSection title={t("home_section_farmer")} />
      <Carousel autoplaySpeed={1000000000}>
        <MiniPost
          image={img_story_1}
          title={t("home_section_farmer1_title")}
          desc = {t("home_section_farmer1_desc")}
          content={t("home_section_farmer1_story")}
        />
        <MiniPost
          image={img_story_2}
          title={t("home_section_farmer2_title")}
          desc = {t("home_section_farmer2_desc")}
          content={t("home_section_farmer2_story")}
        />
        <MiniPost
          image={img_story_3}
          title={t("home_section_farmer3_title")}
          desc = {t("home_section_farmer3_desc")}
          content={t("home_section_farmer3_story")}
        />
        {/* <MiniPost
          image={img_story_1}
          title={t("home_section_farmer1_title")}
          desc = {t("home_section_farmer1_desc")}
          content={t("home_section_farmer1_story")}
        />
        <MiniPost
          image={img_story_1}
          title={t("home_section_farmer2_title")}
          desc = {t("home_section_farmer1_desc")}
          content={t("home_section_farmer1_story")}
        />
        <MiniPost
          image={img_story_1}
          title={t("home_section_farmer3_title")}
          desc = {t("home_section_farmer1_desc")}
          content={t("home_section_farmer1_story")}
        /> */}
      </Carousel>
    </div>
  );
};
