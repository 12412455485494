import React, { useState } from "react";
import styles from "../../assets/styles/form.module.css";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

const CustomGoogleFormModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ email: "", name: "", phone: "" });
  const { t } = useTranslation();

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Required field validation
      if (!formData.name.trim()) {
        Swal.fire({
          icon: "warning",
          title: "Name is required",
          confirmButtonText: "OK",
        });
        return;
      }

      if (!formData.email.trim()) {
        Swal.fire({
          icon: "warning",
          title: "Email is required",
          confirmButtonText: "OK",
        });
        return;
      }

      if (!formData.phone.trim()) {
        Swal.fire({
          icon: "warning",
          title: "Phone number is required",
          confirmButtonText: "OK",
        });
        return;
      }
      const query = new URLSearchParams({
        "entry.1410829400": formData.name,
        "entry.1508195437": formData.email,
        "entry.494254435": formData.phone,
      }).toString();

      const res = await fetch(
        `https://docs.google.com/forms/d/e/1FAIpQLSdW4BDVv9pA5-ZqM0BUmLaqAJN_tI0JiKaDwy3_YJMlYo05pA/formResponse?submit=Submit&${query}`,
        {
          method: "GET",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (!res) {
        Swal.fire({
          icon: "error",
          title: "Submission failed!",
          text: "Please try again.",
          confirmButtonText: "OK",
        });
        return;
      }
      Swal.fire({
        icon: "success",
        title: t("recieve_success"),
        confirmButtonText: "OK",
      });
    } catch (e) {
      console.log("Error", e);
    } finally {
      setFormData({ email: "", name: "", phone: "" });
      setShowModal(false); // Close the modal on successful submission
    }
  };

  return (
    <div className={styles.container}>
      <button
        className={styles.openModalBtn}
        onClick={() => setShowModal(true)}
      >
        {t("recieve_news")}
      </button>
      {showModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <span className={styles.close} onClick={() => setShowModal(false)}>
              &times;
            </span>
            <h2 className={styles.modalTitle}>{t("recieve_news")}</h2>
            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.formItem}>
                <label htmlFor="name">{t("form_contact_name")}</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="enfarm"
                  required
                />
              </div>
              <div className={styles.formItem}>
                <label htmlFor="email">{t("form_contact_email")}</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="lienhe@enfarm.com"
                  required
                />
              </div>
              <div className={styles.formItem}>
                <label htmlFor="phone">{t("form_contact_phone")}</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="1800 888 900"
                  required
                />
              </div>
              <button type="submit" className={styles.submitBtn}>
                {t("form_contact_send")}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomGoogleFormModal;
