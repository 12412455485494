import style from "../assets/styles/map.module.css";

import { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader
} from "@react-google-maps/api";

// import markerPin from "../assets/images/flag/enfarm-flag.png";
// import markerPinActive from "../assets/images/flag/enfarm-flag-active.png";

import markerPin from "../assets/images/Map/location.svg";
import markerPinActive from "../assets/images/Map/location-tham-quan.svg";
import officeIcon from "../assets/images/Map/location-office.svg";
import csrIcon from "../assets/images/Map/location-csr.svg";

interface MarkerData {
  lat: number;
  long: number;
  farmname: string;
  address: string;
  is_exhibit: boolean;
}

const demoIDs = [132, 121, 718, 557, 372, 687, 511, 469, 538, 726];

const MapContent = ({ data }) => {
  const [selectedMarker, setSelectedMarker] = useState<MarkerData | null>(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCqoEHbZ_C0Zg-ex_COcHsSB1O-p6ox4qw", // Thay bằng API key của bạn
    language: "vi", // Ngôn ngữ tiếng Việt
    region: "VN",  // Đặt vùng là Việt Nam
  });

  const office = [
    { farmname: "enfarm HCM", lat: 10.77129, long: 106.70287 },
    { farmname: "enfarm BMT", lat: 12.69081, long: 108.06137 },
  ];

  const csr = [
    { farmname: "Cây sầu riêng 100 tuổi", lat: 12.6926939, long: 108.2691995 },
  ];

  const filterData = data.filter((item) => !demoIDs.includes(item?.farmid));
  const firstLat: number = filterData?.[0]?.lat ?? 0;
  const firstLong: number = filterData?.[0]?.long ?? 0;

  const center = { lat: firstLat, lng: firstLong };

  // Create a custom icon for markers
  const createCustomIcon = (iconUrl, label) => {
    return {
      url: iconUrl,
      scaledSize: new google.maps.Size(42, 42),
      labelOrigin: new google.maps.Point(21, 42),
    };
  };

  const onMarkerClick = (marker: MarkerData) => {
    setSelectedMarker(marker);
  };

  const mapContainerStyle = {
    height: "100vh",
    width: "100%",
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={7}
      mapTypeId="hybrid"
      // onLoad={(map) => (mapRef.current = map)}
    >
      {office.map((item) => (
        <Marker
          key={item.farmname}
          position={{ lat: item.lat, lng: item.long }}
          icon={createCustomIcon(officeIcon, item.farmname)}
          // onClick={() => onMarkerClick(item)}
        />
      ))}

      {csr.map((item) => (
        <Marker
          key={item.farmname}
          position={{ lat: item.lat, lng: item.long }}
          icon={createCustomIcon(csrIcon, item.farmname)}
          // onClick={() => onMarkerClick(item)}
        />
      ))}

      {filterData.map((item) => (
        <Marker
          key={item.farmname}
          position={{ lat: item.lat, lng: item.long }}
          icon={createCustomIcon(
            item.is_exhibit ? markerPinActive : markerPin,
            item.farmname
          )}
          onClick={() => item.is_exhibit && onMarkerClick(item)}
        />
      ))}

      {selectedMarker && (
        <InfoWindow
          position={{ lat: selectedMarker.lat, lng: selectedMarker.long }}
          onCloseClick={() => setSelectedMarker(null)}
        >
          <div>
            <h3>{selectedMarker.farmname}</h3>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : null;
};

export const Map = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<MarkerData[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onInitData();
  }, []);

  const getLocation = async (): Promise<MarkerData[]> => {
    try {
      const response = await axios.get(
        "https://api-ma.enfarm.com/api/v1/ma/get-install-locations"
      );
      return response.data.content;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const onInitData = async () => {
    try {
      setLoading(true);
      const locations = await getLocation();
      setData(locations);
    } catch (e) {
      console.error("Error during data initialization:", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="map-page container" style={{ position: "relative" }}>
      <div className={style.wrapper}>
        <div className="w-full h-full relative">
          <div className={style.map_banner_content}>
            <h1>{t("map_banner_title")}</h1>
          </div>
        </div>
      </div>
      <div className={style.section}>
        {/* <div className={style.notion}>
          <div className={style.notion_item}>
            <div className={style.notion_item__ico}>
              <img src={markerPinActive} alt="active-enfarm" />
            </div>
            <div className={style.notion_item__label}>Vườn tham quan</div>
          </div>
          <div className={style.notion_item}>
            <div className={style.notion_item__ico}>
              <img src={markerPin} alt="enfarm" />
            </div>
            <div className={style.notion_item__label}>Vườn lắp đặt</div>
          </div>
        </div> */}
        {data && data?.length && <MapContent data={data} />}
      </div>
      <div className={style.contactInfo}>
        <p>
          🌿 Hãy gọi ngay hotline{" "}
          <a href="tel:1800888900" className={style.hotlineLink}>
            1800 888 900
          </a>{" "}
          hoặc tới cửa hàng enfarm hay vườn thăm quan gần nhất để trải nghiệm
          công nghệ enfarm
        </p>
      </div>
    </div>
  );
};
