import React from "react";
import { Carousel } from "../../components/Carousel";
import style from "../../assets/styles/section.module.css";
import styleCarousel from "../../assets/styles/carousel.module.css";
import { TittleSection } from "../TittleSection";


import { CaretRight } from "../../assets/images/svg/Icon";
import { useTranslation } from "react-i18next";

interface MiniPostProps {
  image: string;
  date: string;
  title: string;
  content: string;
  link?: string
}

export const NewsSection = () => {
  // const { t } = useTranslation();
  const { t, i18n, ready } = useTranslation();
  const lst_news = t("lst_news", { returnObjects: true });
  const shorten = (title: string, maxLength: number) => {
    if (title?.length > maxLength) {
      return title.slice(0, maxLength) + "...";
    }
    return title;
  };

  const renderList = () => {
    return (lst_news as any).map(function(item: any) { return (
      <div key={item.link}>
        <MiniPost
          image={item.avatar}
          date={item.know_section_news_post_date}
          title={item.know_section_news_post_title}
          content={item.know_section_news_post_subtitle}
          link = {item.links}
        />
      </div>
    )});
  }

  const MiniPost = ({ image, date, title, content, link }: MiniPostProps) => {
    return (
      <div className="p-4">
        <div
          className={`${styleCarousel.minipost_carousel} ${styleCarousel.bg_color}`}
        >
          <div className="w-full overflow-hidden a-16-9">
            <img
              src={image}
              className="object-cover object-top h-full w-full"
              alt=""
            />
          </div>
          <div className="p-4 min-h-[200px] post">
            <p className={style.date}>{date}</p>
            <h3 className={style.title}>{shorten(title, 50)}</h3>
            <p className={style.content}>{shorten(content, 130)}</p>
            <button className={style.read_more}>
              <a href={link} target="_blank" rel="noreferrer">
                {t("read_more")}
                <CaretRight />
              </a>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={style.section}>
      <TittleSection title={t("home_section_news_title")} />

      <Carousel autoplaySpeed={1000000000} showArrows={true}>
        {renderList()}
      </Carousel>
    </div>
  );
};
