import { useEffect } from "react";
import "leaflet/dist/leaflet.css"; // Ensure Leaflet CSS is imported
import "./App.css";
import "./assets/styles/bootstrap-grid.css";
import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import About from "./pages/About";
import { Header } from "./layouts/Header/Header";
import Chatbot from "./components/chatbot/Chatbot";
import Footer from "./layouts/Footer";
import Contact from "./layouts/Contact";
import { Knowledge } from "./pages/Knowledge";
import { Map } from "./pages/Map";
import { Product } from "./pages/Product";
import { Source } from "./pages/Source";
import QRCode from "./pages/QRCodeGenerator";
import { useDispatch } from "react-redux";
import { setActivePage } from "./redux/layoutSlice";
import { useTranslation } from "react-i18next";

function App() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (window.location.pathname.includes("/")) {
      dispatch(setActivePage(0));
    }
    if (window.location.pathname.includes("/about")) {
      dispatch(setActivePage(1));
    }
    if (window.location.pathname.includes("/product")) {
      dispatch(setActivePage(2));
    }
    if (window.location.pathname.includes("/knowledge")) {
      dispatch(setActivePage(3));
    }
    if (window.location.pathname.includes("/map")) {
      dispatch(setActivePage(4));
    }
  }, []);
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/product" element={<Product />} />
        <Route path="/knowledge" element={<Knowledge />} />
        <Route path="/map" element={<Map />} />
        <Route path="/qr-code" element={<QRCode />} />
        <Route
          path="/tri-thuc-1"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/know-how-vi-1.html"
            />
          }
        />
        <Route
          path="/tri-thuc-2"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/know-how-vi-2.html"
            />
          }
        />
        <Route
          path="/tri-thuc-3"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/know-how-vi-3.html"
            />
          }
        />
        <Route
          path="/tri-thuc-4"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/know-how-vi-4.html"
            />
          }
        />
        <Route
          path="/tin-tuc-1"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/news-vi-1.html"
            />
          }
        />
        <Route
          path="/tin-tuc-2"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/news-vi-2.html"
            />
          }
        />
        <Route
          path="/tin-tuc-3"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/news-vi-3.html"
            />
          }
        />
        <Route
          path="/tin-tuc-4"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/news-vi-4.html"
            />
          }
        />
        <Route
          path="/tin-tuc-5"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/news-vi-5.html"
            />
          }
        />
        <Route
          path="/tin-tuc-6"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/news-vi-6.html"
            />
          }
        />
        <Route
          path="/tin-tuc-7"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/news-vi-7.html"
            />
          }
        />
        <Route
          path="/tin-tuc-8"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/news-vi-8.html"
            />
          }
        />
        <Route
          path="/tin-tuc-9"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/news-vi-9.html"
            />
          }
        />
        <Route
          path="/tin-tuc-10"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/news-vi-10.html"
            />
          }
        />
        <Route
          path="/enfarm-thu-hut-khach-quan-quan-tai-trien-lam-nong-nghiep-agri-exhibition-2024"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url={`/sources/enfarm-post/${
                !localStorage.getItem("lang") &&
                localStorage.getItem("lang") === "en"
                  ? "en"
                  : "vi"
              }/enfarm-thu-hut-khach-quan-quan-tai-trien-lam-nong-nghiep-agri-exhibition-2024.html`}
            />
          }
        />
        <Route
          path="/enfarm-tham-du-su-kien-asia-tech-x-singapore-2024"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url={`/sources/enfarm-post/${
                !localStorage.getItem("lang") &&
                localStorage.getItem("lang") === "en"
                  ? "en"
                  : "vi"
              }/enfarm-tham-du-su-kien-asia-tech-x-singapore-2024.html`}
            />
          }
        />

        <Route
          path="/ra-mat-enfarm-f-mot-cham-giai-phap-thau-hieu-dat-dai-and-cay-trong"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url={`/sources/enfarm-post/${
                !localStorage.getItem("lang") &&
                localStorage.getItem("lang") === "en"
                  ? "en"
                  : "vi"
              }/ra-mat-enfarm-f-mot-cham-giai-phap-thau-hieu-dat-dai-and-cay-trong.html`}
            />
          }
        />
        <Route
          path="/cung-nha-nong-huong-toi-nong-nghiep-ben-vung"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url={`/sources/enfarm-post/${
                !!localStorage.getItem("lang") &&
                localStorage.getItem("lang") === "en"
                  ? "en"
                  : "vi"
              }/cung-nha-nong-huong-toi-nong-nghiep-ben-vung.html`}
            />
          }
        />
        <Route
          path="/le-cham-dat-khai-truong-cua-hang-enfarm-buon-ma-thuot"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url={`/sources/enfarm-post/${
                !!localStorage.getItem("lang") &&
                localStorage.getItem("lang") === "en"
                  ? "en"
                  : "vi"
              }/le-cham-dat-khai-truong-cua-hang-enfarm-buon-ma-thuot.html`}
            />
          }
        />
        <Route
          path="/cong-nghe-enfarm-lap-dat-tai-vuon-sau-rieng-co-thu-huyen-krong-pac"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url={`/sources/enfarm-post/${
                !!localStorage.getItem("lang") &&
                localStorage.getItem("lang") === "en"
                  ? "en"
                  : "vi"
              }/cong-nghe-enfarm-lap-dat-tai-vuon-sau-rieng-co-thu-huyen-krong-pac.html`}
            />
          }
        />

        <Route
          path="/enfarm-vao-top-10-cong-ty-khoi-nghiep-dan-dau-dong-nam-a-trong-mang-giai-phap-giam-thieu-bien-doi-khi-hau"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url={`/sources/enfarm-post/${
                !!localStorage.getItem("lang") &&
                localStorage.getItem("lang") === "en"
                  ? "en"
                  : "vi"
              }/enfarm-vao-top-10-cong-ty-khoi-nghiep-dan-dau-dong-nam-a-trong-mang-giai-phap-giam-thieu-bien-doi-khi-hau.html`}
            />
          }
        />

        <Route
          path="/cong-nghe-do-ph-dat-cua-enfarm"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url={`/sources/enfarm-post/${
                !!localStorage.getItem("lang") &&
                localStorage.getItem("lang") === "en"
                  ? "en"
                  : "vi"
              }/cong-nghe-do-ph-dat-cua-enfarm.html`}
            />
          }
        />

        <Route
          path="/durian-conference"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url={`/sources/enfarm-post/${
                !!localStorage.getItem("lang") &&
                localStorage.getItem("lang") === "en"
                  ? "en"
                  : "vi"
              }/durian-conference.html`}
            />
          }
        />
        <Route
          path="/enfarm-top-5-san-pham-ai-xuat-sac"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url={`/sources/enfarm-post/${
                !!localStorage.getItem("lang") &&
                localStorage.getItem("lang") === "en"
                  ? "en"
                  : "vi"
              }/enfarm-top-5-san-pham-ai-xuat-sac.html`}
            />
          }
        />
        <Route
          path="/hoi-thao-ung-dung-ai-de-thuc-day-chuyen-doi-so-trong-nong-nghiep"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url={`/sources/enfarm-post/${
                !!localStorage.getItem("lang") &&
                localStorage.getItem("lang") === "en"
                  ? "en"
                  : "vi"
              }/hoi-thao-ung-dung-ai-de-thuc-day-chuyen-doi-so-trong-nong-nghiep.html`}
            />
          }
        />

        <Route
          path="/knowledge-1"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/know-how-en-1.html"
            />
          }
        />
        <Route
          path="/knowledge-2"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/know-how-en-2.html"
            />
          }
        />
        <Route
          path="/knowledge-3"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/know-how-en-3.html"
            />
          }
        />
        <Route
          path="/knowledge-4"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/know-how-en-4.html"
            />
          }
        />
        <Route
          path="/news-1"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url=""
            />
          }
        />
        <Route
          path="/news-2"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/news-en-2.html"
            />
          }
        />
        <Route
          path="/news-3"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url=""
            />
          }
        />
        <Route
          path="/news-4"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/news-en-4.html"
            />
          }
        />
        <Route
          path="/news-5"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/news-en-5.html"
            />
          }
        />
        <Route
          path="/news-6"
          element={
            <Source
              title="know_banner_title"
              content="know_banner_content"
              url="/sources/news-en-6.html"
            />
          }
        />
      </Routes>
      <Contact />
      <Footer />
      <Chatbot/>
    </>
  );
}

export default App;
