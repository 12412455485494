import * as React from "react";
import { twMerge } from "tailwind-merge";

export const LeafIcon = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    className={twMerge("", className)}
  >
    <path
      fill="#482219"
      d="M25.676 18.884a7.613 7.613 0 0 1-3.978 1.107 8.963 8.963 0 0 1-3.42-.707A6.944 6.944 0 0 0 17 23.314V27a1 1 0 0 1-1.066 1A1.023 1.023 0 0 1 15 26.969v-1.555l-4.828-4.828A6.563 6.563 0 0 1 7.93 21a5.728 5.728 0 0 1-2.99-.834C2.216 18.511.75 14.703 1.034 9.974a1 1 0 0 1 .94-.94c4.729-.279 8.537 1.182 10.187 3.906a5.75 5.75 0 0 1 .806 3.56.499.499 0 0 1-.86.304l-2.4-2.513a1 1 0 0 0-1.414 1.414l6.735 6.906c.007-.097.016-.195.026-.291a8.57 8.57 0 0 1 2.33-4.933l6.323-6.682a1 1 0 1 0-1.413-1.415l-6.125 6.478a.5.5 0 0 1-.848-.218c-.592-2.185-.331-4.36.8-6.227 2.233-3.685 7.428-5.658 13.898-5.278a1 1 0 0 1 .94.94c.375 6.471-1.598 11.666-5.283 13.899Z"
    />
  </svg>
);
export const PhoneBuzzIcon = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    className={twMerge("", className)}
  >
    <path
      fill="#498532"
      d="M1.4 19.25V11.9h2.1v7.35H1.4Zm3.15-3.15V8.75h2.1v7.35h-2.1Zm5.25 8.4a2.023 2.023 0 0 1-1.484-.617A2.018 2.018 0 0 1 7.7 22.4V5.6c0-.577.206-1.072.617-1.484A2.018 2.018 0 0 1 9.8 3.5h8.4c.577 0 1.072.206 1.483.617.412.412.618.906.617 1.483v16.8c0 .578-.206 1.072-.617 1.484a2.018 2.018 0 0 1-1.483.616H9.8Zm0-5.25h8.4V8.75H9.8v10.5Zm11.55 0V11.9h2.1v7.35h-2.1Zm3.15-3.15V8.75h2.1v7.35h-2.1Z"
    />
  </svg>
);
export const WormIcon = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    className={twMerge("", className)}
  >
    <path
      fill="#498532"
      d="M14 7.438C14 5.264 15.79 3.5 18 3.5h1.6c2.062 0 3.733 1.645 3.733 3.675v11.747c0 3.08-2.537 5.578-5.666 5.578-3.13 0-5.667-2.498-5.667-5.578V15.64c0-.907-.746-1.641-1.667-1.641-.92 0-1.667.734-1.667 1.64v6.891c0 1.087-.895 1.969-2 1.969-1.104 0-2-.882-2-1.969v-6.89c0-3.08 2.538-5.579 5.667-5.579 3.13 0 5.667 2.498 5.667 5.579v3.28c0 .907.746 1.642 1.666 1.642.921 0 1.667-.735 1.667-1.641v-7.547H18c-2.209 0-4-1.764-4-3.938Zm6-.329a.97.97 0 0 0-.293-.696A1 1 0 0 0 19 6.125a1.013 1.013 0 0 0-.707.288.983.983 0 0 0-.293.696.97.97 0 0 0 .293.696 1.001 1.001 0 0 0 .707.289 1.013 1.013 0 0 0 .707-.289A.982.982 0 0 0 20 7.11Z"
    />
  </svg>
);
export const ChartIcon = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    className={twMerge("", className)}
  >
    <path
      fill="#498532"
      fillRule="evenodd"
      d="M22.583 7.29h-4.694V5.54h7.681v7.682h-1.75V8.528l-8.848 8.848-4.861-4.861-6.188 6.187-1.237-1.237 7.425-7.425 4.861 4.862 7.61-7.611Zm3.084 17.502H2.332v-1.75h23.335v1.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export const PlantIcon = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    className={twMerge("", className)}
  >
    <path
      fill="#498532"
      d="M22.467 16.523a6.661 6.661 0 0 1-3.482.97 7.844 7.844 0 0 1-2.992-.62 6.076 6.076 0 0 0-1.118 3.527v3.225a.873.873 0 0 1-.933.875.894.894 0 0 1-.817-.902v-1.36L8.9 18.012a5.74 5.74 0 0 1-1.962.362 5.011 5.011 0 0 1-2.617-.73C1.94 16.197.656 12.865.904 8.727a.875.875 0 0 1 .823-.822c4.138-.244 7.47 1.034 8.914 3.417.567.935.815 2.028.706 3.116a.438.438 0 0 1-.754.265l-2.1-2.198a.875.875 0 0 0-1.237 1.237l5.893 6.043c.007-.085.014-.17.023-.255a7.499 7.499 0 0 1 2.039-4.316l5.533-5.847a.875.875 0 1 0-1.237-1.238l-5.36 5.668a.44.44 0 0 1-.626.01.438.438 0 0 1-.115-.2c-.518-1.913-.29-3.816.7-5.45 1.954-3.224 6.5-4.95 12.16-4.618a.875.875 0 0 1 .823.823c.328 5.662-1.398 10.208-4.622 12.161Z"
    />
  </svg>
);
export const FaceBookIcon = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    className={twMerge("", className)}
  >
    <path
      fill="#747474"
      d="M30 15c0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 7.487 5.485 13.693 12.656 14.818V19.336H8.848V15h3.808v-3.305c0-3.759 2.24-5.836 5.666-5.836 1.641 0 3.358.293 3.358.293v3.692h-1.892c-1.863 0-2.444 1.156-2.444 2.342V15h4.16l-.665 4.336h-3.495v10.482C24.514 28.693 30 22.487 30 15Z"
    />
  </svg>
);
export const LinkedinIcon = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={26}
    fill="none"
    className={twMerge("", className)}
  >
    <path
      fill="#747474"
      d="M23.914 22.074h-3.759V16.09c0-1.426-.025-3.263-1.955-3.263-1.957 0-2.257 1.555-2.257 3.16v6.087h-3.758V9.769h3.608v1.682h.05a3.985 3.985 0 0 1 1.51-1.494 3.902 3.902 0 0 1 2.05-.494c3.81 0 4.512 2.547 4.512 5.861l-.001 6.75ZM7.944 8.087c-.432 0-.854-.13-1.212-.373a2.21 2.21 0 0 1-.804-.995 2.251 2.251 0 0 1 .473-2.417 2.148 2.148 0 0 1 2.377-.481c.398.168.739.452.979.817a2.244 2.244 0 0 1-.271 2.8 2.18 2.18 0 0 1-1.542.65Zm1.879 13.987H6.06V9.77h3.763v12.305ZM25.788.104H4.172A1.835 1.835 0 0 0 2.857.64 1.897 1.897 0 0 0 2.3 1.963v22.065c.006.499.206.975.557 1.324.35.349.824.542 1.315.537h21.616a1.84 1.84 0 0 0 1.318-.535c.352-.35.554-.826.56-1.326V1.96a1.9 1.9 0 0 0-.56-1.324 1.839 1.839 0 0 0-1.318-.534"
    />
  </svg>
);
export const StatusSuccess = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    className={twMerge("", className)}
  >
    <path
      fill="#EA9A20"
      d="m12.133 16.4-2.9-2.9a1.223 1.223 0 0 0-.9-.367 1.28 1.28 0 0 0-.933.4c-.245.245-.367.556-.367.934 0 .377.122.688.367.933l3.8 3.8c.244.244.555.367.933.367s.69-.123.934-.367l7.566-7.567c.245-.244.367-.544.367-.9a1.28 1.28 0 0 0-.4-.933c-.245-.244-.556-.367-.934-.367-.377 0-.688.123-.933.367l-6.6 6.6ZM14 27.333c-1.845 0-3.578-.35-5.2-1.05a13.487 13.487 0 0 1-4.234-2.85c-1.2-1.2-2.15-2.61-2.849-4.233-.7-1.622-1.05-3.356-1.05-5.2 0-1.845.35-3.578 1.05-5.2.7-1.622 1.65-3.033 2.85-4.233 1.2-1.2 2.61-2.15 4.233-2.85 1.622-.7 3.355-1.05 5.2-1.05 1.844 0 3.578.35 5.2 1.05 1.622.7 3.033 1.65 4.233 2.85 1.2 1.2 2.15 2.61 2.85 4.233.701 1.622 1.051 3.355 1.05 5.2 0 1.844-.35 3.578-1.05 5.2a13.485 13.485 0 0 1-2.85 4.233c-1.2 1.2-2.61 2.15-4.233 2.851-1.622.7-3.356 1.05-5.2 1.05Z"
    />
  </svg>
);
export const MenuMobileWhite = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    className={twMerge("", className)}
  >
    <path
      fill="#fff"
      d="M4.667 21a1.13 1.13 0 0 1-.832-.336 1.124 1.124 0 0 1-.335-.83c0-.331.112-.608.336-.832.224-.224.5-.336.83-.335h18.667c.33 0 .608.112.832.336.224.224.336.5.335.83 0 .33-.112.608-.336.832-.224.224-.5.336-.83.335H4.666Zm0-5.833a1.13 1.13 0 0 1-.832-.336A1.124 1.124 0 0 1 3.5 14c0-.33.112-.608.336-.832.224-.224.5-.335.83-.335h18.667c.33 0 .608.112.832.336.224.224.336.501.335.831 0 .33-.112.608-.336.832-.224.224-.5.335-.83.335H4.666Zm0-5.834a1.13 1.13 0 0 1-.832-.336 1.124 1.124 0 0 1-.335-.83c0-.33.112-.608.336-.832.224-.224.5-.336.83-.335h18.667c.33 0 .608.112.832.336.224.224.336.5.335.83 0 .331-.112.608-.336.832-.224.225-.5.336-.83.335H4.666Z"
    />
  </svg>
);
export const MenuMobileBlack = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    className={twMerge("", className)}
  >
    <path
      fill="#1A1C18"
      d="M4.667 21a1.13 1.13 0 0 1-.832-.336 1.124 1.124 0 0 1-.335-.83c0-.331.112-.608.336-.832.224-.224.5-.336.83-.335h18.667c.33 0 .608.112.832.336.224.224.336.5.335.83 0 .33-.112.608-.336.832-.224.224-.5.336-.83.335H4.666Zm0-5.833a1.13 1.13 0 0 1-.832-.336A1.124 1.124 0 0 1 3.5 14c0-.33.112-.608.336-.832.224-.224.5-.335.83-.335h18.667c.33 0 .608.112.832.336.224.224.336.501.335.831 0 .33-.112.608-.336.832-.224.224-.5.335-.83.335H4.666Zm0-5.834a1.13 1.13 0 0 1-.832-.336 1.124 1.124 0 0 1-.335-.83c0-.33.112-.608.336-.832.224-.224.5-.336.83-.335h18.667c.33 0 .608.112.832.336.224.224.336.5.335.83 0 .331-.112.608-.336.832-.224.225-.5.336-.83.335H4.666Z"
    />
  </svg>
);
export const ArrowLeft = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    className={twMerge("", className)}
  >
    <path
      fill="#8D9387"
      fillRule="evenodd"
      d="M18.325 4.425a1.167 1.167 0 0 1 0 1.65L10.4 14l7.925 7.925a1.167 1.167 0 0 1-1.65 1.65l-8.75-8.75a1.167 1.167 0 0 1 0-1.65l8.75-8.75a1.167 1.167 0 0 1 1.65 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export const ArrowRight = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    className={twMerge("", className)}
  >
    <path
      fill="#8D9387"
      fillRule="evenodd"
      d="M9.675 4.425a1.167 1.167 0 0 1 1.65 0l8.75 8.75a1.167 1.167 0 0 1 0 1.65l-8.75 8.75a1.167 1.167 0 0 1-1.65-1.65L17.6 14 9.675 6.075a1.167 1.167 0 0 1 0-1.65Z"
      clipRule="evenodd"
    />
  </svg>
);
export const DownloadIcon = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    className={twMerge("", className)}
  >
    <path
      fill="#fff"
      d="M4.167 16.667h11.666V15H4.167v1.667ZM15.833 7.5H12.5v-5h-5v5H4.167L10 13.333 15.833 7.5Z"
    />
  </svg>
);
export const CaretRight = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    className={twMerge("", className)}
  >
    <path
      fill="#0066D5"
      fillRule="evenodd"
      d="M5.529 2.529c.26-.26.682-.26.942 0l5 5c.26.26.26.682 0 .942l-5 5a.667.667 0 1 1-.942-.943L10.057 8 5.53 3.47a.667.667 0 0 1 0-.942Z"
      clipRule="evenodd"
    />
  </svg>
);
export const PeopleTeam = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    className={twMerge("", className)}
  >
    <path d="M22.131 15a2.626 2.626 0 0 1 2.625 2.625v7.123a6.751 6.751 0 1 1-13.503 0v-7.123A2.625 2.625 0 0 1 13.878 15h8.253Zm-11.434 0a4.08 4.08 0 0 0-.93 2.295l-.015.33v7.123c0 1.271.287 2.474.8 3.548A6.002 6.002 0 0 1 3 22.499v-4.874a2.625 2.625 0 0 1 2.409-2.616L5.625 15h5.072Zm14.616 0h5.062A2.626 2.626 0 0 1 33 17.625V22.5a6 6 0 0 1-7.545 5.799c.45-.942.726-1.98.788-3.078l.013-.473v-7.123c0-.997-.352-1.912-.944-2.625ZM18 4.5a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9ZM27.75 6a3.75 3.75 0 1 1 0 7.5 3.75 3.75 0 0 1 0-7.5ZM8.25 6a3.75 3.75 0 1 1 0 7.5 3.75 3.75 0 0 1 0-7.5Z" />
  </svg>
);
export const FoodRamen = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    className={twMerge("", className)}
  >
    <path d="M33 4.5 15 6.615V9h18v1.5H15V18h18c0 2.715-.855 5.19-2.52 7.425-1.665 2.235-3.825 3.87-6.48 4.95V33H12v-2.625c-2.64-1.08-4.815-2.73-6.48-4.95S3 20.715 3 18h4.5V6L33 3v1.5ZM9 7.32V9h1.5V7.17L9 7.32Zm0 3.18V18h1.5v-7.5H9Zm4.5 7.5v-7.5H12V18h1.5Zm0-9V6.825L12 6.96V9h1.5Z" />
  </svg>
);
export const HeartBeat = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    className={twMerge("", className)}
  >
    <path d="M24.429 20.875h5.106a1.693 1.693 0 0 1-.168.174c-.056.05-.106.092-.15.125l-.05.066-10.43 9.961c-.201.2-.447.299-.737.299s-.536-.1-.737-.299L6.817 21.207c-.056-.022-.173-.133-.352-.332h6.178c.245 0 .466-.075.661-.224.195-.15.321-.34.377-.573l1.172-4.665 3.18 11.073c.067.222.196.404.386.548.19.144.407.216.652.216.235 0 .447-.072.637-.216.19-.144.318-.326.385-.548l2.444-8.051.937 1.859c.201.387.52.581.955.581ZM33 13.77c0 1.604-.575 3.265-1.724 4.98h-6.178l-1.858-3.669a1.005 1.005 0 0 0-.427-.448 1.003 1.003 0 0 0-.611-.133c-.502.055-.815.31-.938.764l-2.16 7.138-3.28-11.388a1.042 1.042 0 0 0-.394-.548 1.087 1.087 0 0 0-.661-.216c-.246 0-.463.075-.653.224-.19.15-.312.34-.368.573l-1.942 7.703H4.724C3.574 17.035 3 15.374 3 13.77c0-2.435.709-4.339 2.126-5.711C6.544 6.686 8.502 6 11.002 6c.692 0 1.398.119 2.118.357s1.39.559 2.009.963c.62.404 1.152.783 1.599 1.137.446.354.87.73 1.272 1.129a15.39 15.39 0 0 1 1.272-1.129c.447-.354.98-.733 1.6-1.137a8.603 8.603 0 0 1 2.008-.963A6.724 6.724 0 0 1 24.998 6c2.5 0 4.458.686 5.876 2.059C32.29 9.43 33 11.335 33 13.769Z" />
  </svg>
);
export const WaterDrop = ({ className = "" }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={twMerge("", className)}
  >
    <g id="content/water">
      <path
        id="Vector"
        d="M18.591 5.32897C18.518 5.24371 18.4274 5.17527 18.3254 5.12835C18.2235 5.08143 18.1126 5.05713 18.0003 5.05713C17.8881 5.05713 17.7772 5.08143 17.6752 5.12835C17.5733 5.17527 17.4827 5.24371 17.4097 5.32897C15.3581 7.72847 8.66895 16.024 8.66895 22.1689C8.66895 27.8952 12.2744 31.5 18 31.5C23.7256 31.5 27.331 27.8952 27.331 22.1689C27.331 16.024 20.6418 7.72847 18.591 5.32897ZM19.0368 28.1304C18.9122 28.1307 18.7894 28.101 18.6788 28.044C18.5681 27.9869 18.4727 27.9041 18.4007 27.8025C18.3287 27.7009 18.2821 27.5834 18.2649 27.4601C18.2477 27.3367 18.2604 27.2111 18.302 27.0936C18.355 26.9404 18.455 26.8079 18.5877 26.7148C18.7205 26.6217 18.8792 26.5729 19.0413 26.5752C20.0706 26.573 21.057 26.1632 21.7848 25.4354C22.5126 24.7076 22.9225 23.7211 22.9247 22.6918C22.9223 22.5297 22.9712 22.371 23.0642 22.2383C23.1573 22.1055 23.2899 22.0055 23.4431 21.9525C23.5605 21.911 23.6862 21.8983 23.8096 21.9154C23.9329 21.9326 24.0503 21.9792 24.1519 22.0512C24.2536 22.1232 24.3364 22.2186 24.3934 22.3293C24.4505 22.44 24.4802 22.5628 24.4799 22.6873C24.4783 24.1304 23.9044 25.514 22.8839 26.5345C21.8635 27.5549 20.4799 28.1289 19.0368 28.1304Z"
      />
    </g>
  </svg>
);
export const WeatherCloudy = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    className={twMerge("", className)}
  >
    <path d="M20.248 13.5a7.875 7.875 0 0 0-7.794 6.75h-.08a5.625 5.625 0 1 0 0 11.25h15.75a5.625 5.625 0 1 0 0-11.25h-.082a7.875 7.875 0 0 0-7.792-6.75h-.002Zm-.477-7.155a1.125 1.125 0 0 0-1.987-1.058l-.959 1.807a1.126 1.126 0 0 0 1.987 1.058l.959-1.807Zm-8.487-1.68a1.126 1.126 0 0 0-2.154.659l.601 1.957a1.125 1.125 0 0 0 2.151-.66l-.598-1.957ZM6.75 15.75c0 1.359.403 2.623 1.093 3.683a7.778 7.778 0 0 1 2.79-1.24 10.138 10.138 0 0 1 8.01-6.817A6.75 6.75 0 0 0 6.75 15.75ZM4.095 9.482a1.125 1.125 0 1 0-1.058 1.986l1.807.959a1.125 1.125 0 1 0 1.058-1.987l-1.81-.958h.003Zm.936 10.041a1.125 1.125 0 0 0-.66-2.15l-1.957.598a1.126 1.126 0 1 0 .66 2.15l1.957-.598Z" />
  </svg>
);
export const Chart = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    className={twMerge("", className)}
  >
    <path
      fillRule="evenodd"
      d="M29.035 9.374H23v-2.25h9.876V17h-2.25v-6.035L19.25 22.34 13 16.091l-7.956 7.955-1.59-1.591 9.545-9.546 6.251 6.25 9.785-9.785ZM33 31.876H3v-2.25H33v2.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export const BalanceSale = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    className={twMerge("", className)}
  >
    <path d="M14.85 21.337h-.001c0-.796.066-.43-4.186-8.933-.869-1.737-3.356-1.74-4.226 0-4.288 8.577-4.186 8.162-4.186 8.933H2.25c0 2.175 2.82 3.938 6.3 3.938s6.3-1.763 6.3-3.938Zm-6.3-7.874 3.544 7.087H5.006l3.544-7.088Zm25.199 7.874c0-.796.066-.43-4.186-8.933-.869-1.737-3.356-1.74-4.226 0-4.288 8.577-4.186 8.162-4.186 8.933h-.001c0 2.175 2.82 3.938 6.3 3.938s6.3-1.763 6.3-3.938h-.001Zm-9.843-.787 3.544-7.088 3.544 7.088h-7.088Zm4.332 6.3h-8.663V12.343a3.94 3.94 0 0 0 2.283-2.818h6.38a.787.787 0 0 0 .787-.787V7.163a.787.787 0 0 0-.787-.788h-7.106A3.919 3.919 0 0 0 18 4.8a3.919 3.919 0 0 0-3.132 1.575H7.762a.787.787 0 0 0-.787.788v1.575c0 .435.352.787.788.787h6.379a3.94 3.94 0 0 0 2.283 2.818V26.85H7.762a.787.787 0 0 0-.787.787v1.575c0 .436.352.788.788.788h20.475a.787.787 0 0 0 .787-.788v-1.575a.787.787 0 0 0-.787-.787Z" />
  </svg>
);
export const FarmHome = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    className={twMerge("", className)}
  >
    <path d="M33 12.96C32.5 9 29.125 6 24.875 6c-2.125 0-4.25.84-5.75 2.28-.875.84-1.5 1.68-1.875 2.76l7 4.44v.12H28V18h-3.75v2.4H28v2.4h-3.75v2.4H28v2.4h-3.75V30H33V12.96ZM19.625 12c.75-2.16 2.875-3.6 5.25-3.6s4.5 1.44 5.25 3.6h-10.5ZM3 16.8V30h6.25v-8.4h6.25V30h6.25V16.8l-9.375-6L3 16.8Z" />
  </svg>
);