import { TittleSection } from "../TittleSection";
// import style from "../../assets/styles/about.module.css";
import styleSection from "../../assets/styles/section.module.css";
// import bg_cafe from "../../assets/images/Home/bg_cafe_small.png";
import { useTranslation } from "react-i18next";

export const VisionSection = () => {
  const { t } = useTranslation();

  return (
    <div className={styleSection.section}>
      <div className="p sm:mt-0 sm:py-2">
        <TittleSection title={t("about_section_vison_title")} />
        <div className="flex justify-center sm:px-0">
          <div className="w-1/2 sm:w-full p-4 rounded-md">
            <h2 className="text-[#306B1B] text-[2rem] text-center sm:text-[2rem] leading-10">
              {t("about_section_vison_content")}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};
