import React from "react";
import style from "../assets/styles/about.module.css";
import styleSection from "../assets/styles/section.module.css";
import { AboutSection } from "../layouts/AboutSection/AboutSection";
import { VisionSection } from "../layouts/AboutSection/VisionSection";
import { MissionSection } from "../layouts/AboutSection/MissionSection";
import { PersonSection } from "../layouts/AboutSection/PersonSection";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className={style.content_about_us}>
          <h1>{t("about_banner_title")}</h1>
          <p>{t("about_banner_content")}</p>
        </div>
      </div>
      <main>
        <div className={`${styleSection.vision} bg-[#F1F1EA]`}>
          <VisionSection />
        </div>
        <div className={styleSection.section}>
          <MissionSection />
        </div>
        <div className={`${styleSection.section} bg-[#F1F1EA]`}>
          <PersonSection />
        </div>
        <div className={styleSection.section}>
          <AboutSection />
        </div>
      </main>
    </div>
  );
};

export default About;
